<script>
//import {getHostUrl} from "@/helpers/api-config";
//import axios from "axios";

import Layout from "../../layouts/auth";
import {
  // authMethods,
  apiAuthMethods,
  notificationMethods,
} from "@/state/helpers";
import { mapState } from "vuex";

// import appConfig from "@/app.config";
import { required, /*email, */helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import store from "@/state/store";

/**
 * Login component
 */
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  page: {
    title: "로그인",
    /*
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
    */
  },
  components: {
    Layout,
  },
  data() {
    return {
      loginForm: {
        email: "",
        password: "",
      },
      isLoginSubmitted: false,

      codeForm: {
        code: "",
      },
      isCodeSubmitted: false,

      step: 1,
    };
  },
  created() {
    document.title = "도와줘 관리자";
  },
  computed: {
    ...mapState("apiAuth", ["status"]),
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  validations: {
    loginForm: {
      email: {
        required: helpers.withMessage("아이디를 입력해 주세요.", required),
        // email: helpers.withMessage("Please enter valid email", email),
      },
      password: {
        required: helpers.withMessage("비밀번호를 입력해 주세요.", required),
      }
    },
    codeForm: {
      code: {
        required: helpers.withMessage("인증코드를 입력해 주세요.", required),
      }
    }
  },
  methods: {
    // ...authMethods,
    ...apiAuthMethods,
    ...notificationMethods,

    tryToLogIn() {
      store.dispatch('notification/clear')

      this.isLoginSubmitted = true
      // stop here if form is invalid
      this.v$.$touch();

      if (this.v$.loginForm.$invalid) {
        this.isLoginSubmitted = false
      } else {
        let params = {
          email: this.loginForm.email,
          password: this.loginForm.password,
        }
        store.dispatch("apiAuth/login", params).then(() => {
          let super_verified = sessionStorage.getItem('super_verified');
          if(super_verified === 'verified') {
            this.$router.push('/');
          } else {
            this.v$.codeForm.$reset();
            this.step = 2
          }
        }, () => {
          this.isLoginSubmitted = false
        });
      }
    },

    resendAuthCode() {
      store.dispatch('notification/clear')
      store.dispatch("apiAuth/authCodeSend").then(() => {
        store.dispatch('notification/success', '카카오 인증코드가 전송되었습니다.', { root: true })
      }, () => {
        store.dispatch('notification/error', '카카오 인증코드 전송실패.', { root: true })
      });
    },
    tryToAuthCode() {
      store.dispatch('notification/clear')

      this.isCodeSubmitted = true
      // stop here if form is invalid
      this.v$.$touch();

      if (this.v$.codeForm.$invalid) {
        this.isCodeSubmitted = false
      } else {
        let params = {
          code: this.codeForm.code
        }
        store.dispatch("apiAuth/authCodeVerify", params).then(() => {
          this.$router.push('/');
        }, () => {
          this.isCodeSubmitted = false
        });
      }
    },
  },
  mounted() {
    const superVerified = sessionStorage.getItem('super_verified')
    this.step = (superVerified === 'verified') ? 2 : 1
  },
};
</script>

<template>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="bg-soft bg-primary">
            <div class="row">
              <div class="col-7">
                <div class="text-primary p-4">
                  <h5 class="text-primary">환영합니다!</h5>
                  <p>Sign in to continue to Admin.</p>
                </div>
              </div>
              <div class="col-5 align-self-end">
                <img
                  src="@/assets/images/profile-img.png"
                  alt
                  class="img-fluid"
                />
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div>
              <router-link to="/">
                <div class="avatar-md profile-user-wid mb-4">
                  <span class="avatar-title rounded-circle bg-light">
                    <img src="@/assets/images/logo-color.png" alt height="54" />
                  </span>
                </div>
              </router-link>
            </div>
            <div
              v-if="notification.message"
              :class="'alert ' + notification.type"
            >
              {{ notification.message }}
            </div>
            <div class="form-layout" :class="{'form-layout-authcode' : step === 2}">
            <b-form class="form-auth p-2" :class="{ 'hide-left' : step === 2}" @submit.prevent="tryToLogIn">
              <b-form-group
                class="mb-3"
                id="input-group-1"
                label="아이디"
                label-for="input-1"

                :invalid-feedback="v$.loginForm.email.$error ? v$.loginForm.email.$errors[0].$message : null"
                :state="!v$.loginForm.email.$error"
              >
                <b-form-input
                  id="input-1"
                  v-model="loginForm.email"
                  type="text"
                  placeholder="아이디 입력"
                  :state="!v$.loginForm.email.$error"
                  :disabled="isLoginSubmitted"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                class="mb-3"
                id="input-group-2"
                label="비밀번호"
                label-for="input-2"

                :invalid-feedback="v$.loginForm.password.$error ? v$.loginForm.password.$errors[0].$message : null"
                :state="!v$.loginForm.password.$error"
              >
                <b-form-input
                  id="input-2"
                  v-model="loginForm.password"
                  type="password"
                  placeholder="비밀번호 입력"
                  :state="!v$.loginForm.password.$error"
                  :disabled="isLoginSubmitted"
                ></b-form-input>
              </b-form-group>

              <div class="mt-3 d-grid">
                <b-button type="submit" variant="primary" class="btn-block" :disabled="isLoginSubmitted">로그인</b-button>
              </div>
            </b-form>
            <b-form class="form-auth p-2" :class="{ 'hide-right' : step === 1}" @submit.prevent="tryToAuthCode">

              <b-form-group
                  class="mb-3"
                  id="input-group-3"
                  label="인증코드"
                  label-for="input-3"
                  :invalid-feedback="v$.codeForm.code.$error ? v$.codeForm.code.$errors[0].$message : null"
                  :state="!v$.codeForm.code.$error"
              >
                <div class="input-group">
                  <b-form-input
                      id="input-3"
                      v-model="codeForm.code"
                      type="password"
                      placeholder="인증코드 입력"
                      :state="!v$.codeForm.code.$error"
                      :disabled="isCodeSubmitted"
                  ></b-form-input>

                  <b-button type="button" variant="outline-success" @click.prevent="resendAuthCode">
                    재전송
                  </b-button>
                </div>
              </b-form-group>
              <div class="mt-3 d-grid">
                <b-button type="submit" variant="info" class="btn-block" :disabled="isCodeSubmitted"
                >인증확인</b-button
                >
              </div>
            </b-form>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="mt-5 text-center">

          <p>
            © {{ new Date().getFullYear() }} 도와줘. Crafted with
            <i class="mdi mdi-heart text-danger"></i> by 지아소프트
          </p>
        </div>
        <!-- end row -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<style scoped>
.form-layout {
  transition: 0.5s;
  position: relative;
  height: 240px;
}
.form-layout-authcode {
  height: 160px;
}
.form-auth {
  width:100%;
  transition: 0.5s;
  position: absolute;
  left:0;
  top:0;
}
.hide-left {
  left: -110%;
}
.hide-right {
  left: 110%;
}
</style>